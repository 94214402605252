<template>
    <div class="tw-space-y-2">
        <div class="row">
            <div class="col-md-12">
                <label class="required tw-text-xs">{{
                    $t('productCategory.code')
                }}</label>
                <input
                    v-model="model.category_code"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('category_code')
                    }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('category_code')"
                >
                    {{ errors.first('category_code') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="tw-text-xs">{{
                    $t('productCategory.nameKh')
                }}</label>
                <input
                    v-model="model.category_name_kh"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('category_name_kh')
                    }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('category_name_kh')"
                >
                    {{ errors.first('category_name_kh') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="required tw-text-xs">{{
                    $t('productCategory.nameEn')
                }}</label>
                <input
                    v-model="model.category_name_en"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('category_name_en')
                    }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('category_name_en')"
                >
                    {{ errors.first('category_name_en') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="tw-text-xs">{{
                    $t('productCategory.icon')
                }}</label>
                <input
                    v-model="model.icon"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('icon')
                    }"
                />
                <small
                    ><i
                        ><a
                            href="https://fontawesome.com/v5/search?o=r&m=free"
                            target="_blank"
                            >{{ $t('productCategory.findYourIcon') }}</a
                        ></i
                    ></small
                >
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('icon')"
                >
                    {{ errors.first('icon') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 tw-mt-3 tw-text-right">
                <ts-button
                    class="tw-mr-2"
                    @click.prevent="() => $emit('cancel')"
                >
                    {{ $t('cancel') }}
                </ts-button>
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    @click.prevent="onSave"
                    :waiting="waiting"
                    >{{ $t('save') }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                    >{{ $t('update') }}</ts-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'warehouseForm',
    data () {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            model: {
                category_id: 0,
                category_code: null,
                category_name_kh: null,
                category_name_en: null,
                icon: null
            }
        }
    },
    created () {
        this.setEditData()
    },
    computed: {
        ...mapState('product/productCategory', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        onSave () {
            this.waiting = true
            this.$store
                .dispatch('product/productCategory/store', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('product/productCategory/update', {
                    id: this.model.category_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.model.category_id = 0
            this.model.category_code = null
            this.model.category_name_kh = null
            this.model.category_name_en = null
            this.model.icon = null
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.category_id = this.edit_data.category_id
                this.model.category_code = this.edit_data.category_code
                this.model.category_name_kh = this.edit_data.category_name_kh
                this.model.category_name_en = this.edit_data.category_name_en
                this.model.icon = this.edit_data.icon
            }
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PRODUCT CATEGORY',
                desc: not.text
            })
        }
    }
}
</script>
